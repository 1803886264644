// @ts-nocheck
/* eslint-enable */
import { getGlobalLogger } from '@zg-rentals/logger-base';
import IconX from 'images/icons/x.svg';

const logger = getGlobalLogger('modules/map');

class MapController {
  infoWindowStates = new WeakMap();

  closeAllInfoWindows = () => {
    // Reset all info window states in the WeakMap
    this.infoWindowStates = new WeakMap();

    // Aggressively remove any popup-related elements from the DOM
    const popupSelectors = [
      '.map-popup-container',
      '.map-popup-bubble',
      '.map-popup-bubble-anchor',
      '.map-popup-content',
    ];

    popupSelectors.forEach((selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        if (element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    });
  };

  createMarker = (markerDetails = {}) => {
    const { icon, lat, lon, map, onClick, zIndex } = markerDetails;
    let clickable = true;

    // If no map object, nothing is gonna happen.
    if (!map) {
      return false;
    }

    // Ensure we have valid info, if not, get out of here.
    if (!lat || !lon) {
      logger?.warn('Do not have geo info for listing, do not add marker');
      return false;
    }

    if (!onClick) {
      clickable = false;
    }

    // Initializes a new marker object and adds it to the map.
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(lat, lon),
      anchorPoint: new window.google.maps.Point(0, -30), // Might need to be props (e.g., for dotmap)
      clickable,
      map,
      icon,
      zIndex,
    });

    return marker;
  };

  createInfoWindow = (marker, content) => {
    if (!marker) {
      return;
    } else if (!marker.icon) {
      return marker;
    }

    if (!content) {
      marker.infoWindow = {
        close: () => {},
        getContent: () => {},
        getPosition: () => {},
        getZIndex: () => {},
        open: () => {},
        setContent: () => {},
        setOptions: () => {},
        setPosition: () => {},
        setZIndex: () => {},
      };
      this.infoWindowStates.set(marker.infoWindow, false);
      return marker;
    }
    const height = marker.icon.scaledSize.height;
    let infoWindowOffSet = 0;
    if (height === 37) {
      infoWindowOffSet = height + 25;
    } else if (height === 32) {
      infoWindowOffSet = height + 25;
    } else {
      infoWindowOffSet = height + 25;
    }

    const POPUP_VERTICAL_OFFSET = -26;

    class Popup extends window.google.maps.OverlayView {
      position: google.maps.LatLng;
      containerDiv: HTMLDivElement;
      contentDiv: HTMLDivElement;
      pixelOffset: number;
      anchor: unknown;

      constructor(position: google.maps.LatLng, content: string | HTMLElement, pixelOffset: number) {
        super();
        this.position = position;
        this.pixelOffset = pixelOffset;
        this.anchor = null;
        this._listenersAdded = false;

        // Popup initialization

        // Create content div
        this.contentDiv = document.createElement('div');
        this.contentDiv.classList.add('map-popup-content');

        if (typeof content === 'string') {
          this.contentDiv.innerHTML = content;
        } else {
          this.contentDiv.appendChild(content);
        }

        const closeButton = document.createElement('button');
        closeButton.setAttribute('aria-label', 'Close popup');
        closeButton.setAttribute('title', 'Close');
        closeButton.style.position = 'absolute';
        closeButton.style.top = '8px';
        closeButton.style.right = '8px';
        closeButton.style.cursor = 'pointer';
        closeButton.style.width = '16px';
        closeButton.style.height = '16px';
        closeButton.style.zIndex = '10';
        closeButton.style.background = 'transparent';
        closeButton.style.border = 'none';
        closeButton.style.padding = '0';
        closeButton.classList.add('popup-close-button');

        const closeImg = document.createElement('img');
        closeImg.src = IconX;
        closeImg.width = 16;
        closeImg.height = 16;
        closeImg.alt = 'Close';
        closeButton.appendChild(closeImg);

        closeButton.addEventListener('click', (e) => {
          e.stopPropagation();
          this.close();
        });

        // Create bubble - note: close button must come BEFORE bubble for CSS selectors to work
        const popupWrapper = document.createElement('div');
        popupWrapper.style.position = 'relative';

        // Add close button first for CSS focus selectors
        popupWrapper.appendChild(closeButton);

        const bubble = document.createElement('div');
        bubble.classList.add('map-popup-bubble');
        bubble.appendChild(this.contentDiv);
        popupWrapper.appendChild(bubble);

        // Create bubble anchor (contains the pointer)
        const bubbleAnchor = document.createElement('div');
        bubbleAnchor.classList.add('map-popup-bubble-anchor');
        bubbleAnchor.appendChild(popupWrapper);

        // Create container
        this.containerDiv = document.createElement('div');
        this.containerDiv.classList.add('map-popup-container');
        this.containerDiv.setAttribute('role', 'dialog');
        this.containerDiv.setAttribute('aria-modal', 'true');
        this.containerDiv.setAttribute('aria-label', 'Location information');

        // Make container trap focus (for a11y support)
        this.containerDiv.setAttribute('tabindex', '0');

        // Set up focus trap to ensure proper tab order
        this.containerDiv.addEventListener('keydown', (e) => {
          // If Tab is pressed and container is focused, move focus to close button
          if (e.key === 'Tab' && document.activeElement === this.containerDiv) {
            e.preventDefault();
            closeButton.focus();
          }
        });

        // Add blur handler to handle when focus moves outside container
        this.containerDiv.addEventListener('focusout', (e) => {
          // If focus moves outside the container, remove focus class
          if (!this.containerDiv.contains(e.relatedTarget as Node)) {
            this.containerDiv.classList.remove('focus-within');
          }
        });

        // Add focus handler to add focus class
        this.containerDiv.addEventListener('focusin', () => {
          this.containerDiv.classList.add('focus-within');
        });
        this.containerDiv.appendChild(bubbleAnchor);
        this.containerDiv.style.display = 'none';
      }

      onAdd() {
        this.getPanes().floatPane.appendChild(this.containerDiv);
      }

      onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }

      draw() {
        const projection = this.getProjection();
        if (!projection) {
          return;
        }

        // Get position from anchor if available, otherwise use the initial position
        const position = this.anchor && this.anchor.getPosition ? this.anchor.getPosition() : this.position;
        const pixelPosition = projection.fromLatLngToDivPixel(position);

        // Only display if within reasonable bounds of the map
        const display = Math.abs(pixelPosition.x) < 4000 && Math.abs(pixelPosition.y) < 4000 ? 'block' : 'none';

        if (display === 'block') {
          // First make the popup visible to measure its width
          this.containerDiv.style.visibility = 'hidden';
          this.containerDiv.style.display = 'block';

          // Position above the marker with the specified offset
          this.containerDiv.style.top = pixelPosition.y - this.pixelOffset - POPUP_VERTICAL_OFFSET + 'px';

          // Get the actual width of the bubble for perfect centering
          const bubbleElement = this.containerDiv.querySelector('.map-popup-bubble');
          const bubbleWidth = bubbleElement ? bubbleElement.offsetWidth : 280;

          // Center horizontally by positioning at pixel position and adjusting by half width
          this.containerDiv.style.left = pixelPosition.x - bubbleWidth / 2 + 'px';
          const bubbleAnchor = this.containerDiv.querySelector('.map-popup-bubble-anchor');
          if (bubbleAnchor) {
            bubbleAnchor.style.width = bubbleWidth + 'px';
          }

          // Force visibility after positioning
          this.containerDiv.style.opacity = '1';
          this.containerDiv.style.visibility = 'visible';
          this.containerDiv.style.pointerEvents = 'auto';
        }

        if (this.containerDiv.style.display !== display && display === 'none') {
          this.containerDiv.style.display = display;
        }
      }
      open(options, anchor) {
        if (typeof options !== 'object') {
          // Legacy signature: open(map, anchor)
          const map = options;
          if (anchor && anchor.getPosition) {
            this.anchor = anchor;
            this.position = anchor.getPosition();
          }
          if (map) {
            this.setMap(map);
            this._setupMapListeners(map);

            // Force a redraw after a short delay to ensure proper positioning
            setTimeout(() => {
              this.draw();

              // Focus the container itself so that tab will go to close button next
              if (this.containerDiv) {
                setTimeout(() => {
                  this.containerDiv.focus();
                }, 100);
              }
            }, 10);
          }
        } else {
          if (options.anchor && options.anchor.getPosition) {
            this.anchor = options.anchor;
            this.position = options.anchor.getPosition();
          }
          if (options.map) {
            this.setMap(options.map);
            this._setupMapListeners(options.map);

            // Force a redraw after a short delay to ensure proper positioning
            setTimeout(() => {
              this.draw();

              // Focus the container itself so that tab will go to close button next
              if (this.containerDiv) {
                setTimeout(() => {
                  this.containerDiv.focus();
                }, 100);
              }
            }, 10);
          }
        }
      }

      _setupMapListeners(map) {
        // Add listeners to handle map panning
        if (!this._listenersAdded && map) {
          this._listenersAdded = true;

          // Hide popup during drag to prevent flickering
          window.google.maps.event.addListener(map, 'dragstart', () => {
            if (this.containerDiv) {
              this.containerDiv.style.opacity = '0';
              this.containerDiv.style.transition = 'opacity 0.1s ease';
            }
          });

          // Show popup after drag ends
          window.google.maps.event.addListener(map, 'dragend', () => {
            if (this.containerDiv) {
              this.containerDiv.style.opacity = '1';
            }
          });

          // Clean up listeners when popup is closed
          window.google.maps.event.addListener(this, 'map_changed', () => {
            if (!this.getMap() && this._listenersAdded) {
              window.google.maps.event.clearListeners(map, 'dragstart');
              window.google.maps.event.clearListeners(map, 'dragend');
              this._listenersAdded = false;
            }
          });
        }
      }
      close() {
        // Clean up any listeners before closing
        if (this._listenersAdded && this.getMap()) {
          window.google.maps.event.clearListeners(this.getMap(), 'dragstart');
          window.google.maps.event.clearListeners(this.getMap(), 'dragend');
          this._listenersAdded = false;
        }
        this.setMap(null);
      }
      setZIndex(zIndex) {
        if (this.containerDiv) {
          this.containerDiv.style.zIndex = zIndex.toString();
        }
      }
      setContent(content) {
        if (this.contentDiv) {
          // Clear existing content
          while (this.contentDiv.firstChild) {
            this.contentDiv.removeChild(this.contentDiv.firstChild);
          }

          // Add new content
          if (typeof content === 'string') {
            this.contentDiv.innerHTML = content;
          } else if (content instanceof HTMLElement) {
            this.contentDiv.appendChild(content);
          }
        }
      }
    }

    const popup = new Popup(marker.getPosition(), content, infoWindowOffSet);
    // Removed popup.setMap() so the popup remains hidden by default until opened.
    this.infoWindowStates.set(popup, false);
    marker.infoWindow = popup;
    return marker;
  };

  handleShowInfoWindow = (marker, bool) => {
    if (!marker.infoWindow || (!marker.infoWindow && !bool)) {
      return;
    }

    const isCurrentlyOpen = this.infoWindowStates.get(marker.infoWindow);

    // Prevent redrawing open InfoWindow (causes blinking) if already open.
    if (bool && isCurrentlyOpen) {
      if (typeof marker.infoWindow.draw === 'function') {
        marker.infoWindow.draw();
      }
      return;
    }

    if (bool) {
      if (window.innerWidth < 768) {
        return;
      }
      // Why 999? Because a clicked infoWindow is ~600
      // and we want a highlighted window to appear above.
      marker.infoWindow.setZIndex(999);
      marker.infoWindow.open({
        map: window.map,
        anchor: marker,
        shouldFocus: false, // Prevent changing browser focus on open
      });
      this.infoWindowStates.set(marker.infoWindow, true);

      // Force redraw to ensure proper positioning
      setTimeout(() => {
        if (marker.infoWindow && typeof marker.infoWindow.draw === 'function') {
          marker.infoWindow.draw();
        }
      }, 10);
    } else if (isCurrentlyOpen) {
      marker.infoWindow.close();
      this.infoWindowStates.set(marker.infoWindow, false);
    }
  };

  handleMarkerMouseOut = ({ marker = {}, zIndex }) => {
    if (marker.infoWindow) {
      this.handleShowInfoWindow(marker, false);
      marker.setOptions({ zIndex });
    }
  };

  handleMarkerMouseOver = (marker = {}) => {
    marker.setOptions({ zIndex: 999 });
    this.handleShowInfoWindow(marker, true);
  };
}

const controller = new MapController();
export default controller;
