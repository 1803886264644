import type { ReactElement } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { iconForListing } from 'app/client/utils/map/gmapIcons';
import controllerMarkers from './controller';
import MapMarker from 'app/shared/modules/map/MapMarker';
import mapMarkerController from 'app/shared/modules/map/MapMarker/controller';
import { MapPopupGlobalStyle } from 'app/shared/modules/map/MapMarker/PopupStyles';
import type { ListingDetails } from 'app/types/listingDetails.type';

interface Marker {
  listing: ListingDetails;
  setIcon: (icon: unknown) => void;
  infoWindow: {
    open: (map: unknown, marker: Marker) => void;
    close: () => void;
    setZIndex: (z: number) => void;
  };
  setOptions: (options: unknown) => void;
}

interface Props {
  activeMarkerMaloneLotId: string;
  currentListing: ListingDetails;
  isMobile: boolean;
  listings: Array<ListingDetails>;
  map: object | null | undefined;
  onListingMarkerMouseOut: () => void;
  onListingMarkerMouseOver: () => void;
  onMarkerClick: () => void;
}

const ListingMarkers: React.FC<Props> = (props: Props) => {
  const {
    activeMarkerMaloneLotId,
    currentListing,
    onListingMarkerMouseOut,
    onListingMarkerMouseOver,
    onMarkerClick,
    map,
    listings,
  } = props;

  const [listingMapMarkers, updateListingMapMarkers] = useState<Array<ReactElement>>([]);
  // Use a ref instead of state for marker store to ensure it persists across renders
  const markerStoreRef = useRef<Record<string, unknown>>({});

  // Store references to markers displayed on map for later quick lookup,
  // as opposed to re-rendering the entire array of markers in order to
  // find the one that we want to set as an active marker.
  const storeMarkerRef = (marker: Marker, listing: ListingDetails): void => {
    marker.listing = listing; // Needed for later reference in determining active icon...
    markerStoreRef.current[listing.maloneLotIdEncoded] = marker;
  };

  // Generate array of listing markers displayed on map and only
  // update components when listings have actually changed.
  useEffect(() => {
    const listingMarkerComponentArray: Array<ReactElement> = [];

    for (let i = 0, total = listings.length; i < total; i++) {
      const listing = listings[i];

      listingMarkerComponentArray.push(
        <MapMarker
          dataObj={listing}
          icon={iconForListing(listing)}
          infoWindowContent={controllerMarkers.listingInfoWindowContent(listing)}
          key={`listing-${listing.maloneLotIdEncoded}`}
          lat={listing.geo.lat}
          lon={listing.geo.lon}
          map={map}
          onClick={onMarkerClick}
          onMouseOut={onListingMarkerMouseOut}
          onMouseOver={onListingMarkerMouseOver}
          storeMarkerRef={storeMarkerRef}
          zIndex={2}
        />,
      );
    }

    updateListingMapMarkers(listingMarkerComponentArray);
    // eslint-disable-next-line
  }, [currentListing, listings]);

  // Handle toggle of active marker without causing entire listing array to rerender.
  useEffect(() => {
    // If activeMarkerMaloneLotId is null, close any open info windows
    if (!activeMarkerMaloneLotId) {
      // Use the controller's utility method to force close all info windows
      mapMarkerController.closeAllInfoWindows();

      // Also reset all markers to their normal state
      Object.values(markerStoreRef.current).forEach((markerObj) => {
        const marker = markerObj as Marker;
        if (marker && marker.infoWindow) {
          mapMarkerController.handleShowInfoWindow(marker, false);
          const icon = iconForListing(marker.listing, false);
          marker.setIcon(icon);
          marker.setOptions({ zIndex: 2 });
        }
      });
      return;
    }

    const marker = markerStoreRef.current[activeMarkerMaloneLotId] as Marker;
    if (!marker || !map) {
      return;
    }

    let icon = iconForListing(marker.listing, true);
    marker.setIcon(icon);
    // Let's use the mapMarkerController to open the info window consistently
    mapMarkerController.handleShowInfoWindow(marker, true);
    marker.infoWindow.setZIndex(999);
    marker.setOptions({ zIndex: 999 });

    return () => {
      icon = iconForListing(marker.listing, false);
      // Use the controller to close the info window properly
      mapMarkerController.handleShowInfoWindow(marker, false);
      marker.setIcon(icon);
      marker.setOptions({ zIndex: 998 });
    };

    // eslint-disable-next-line
  }, [activeMarkerMaloneLotId]);

  return (
    <>
      <MapPopupGlobalStyle />
      {listingMapMarkers}
    </>
  );
};

export default ListingMarkers;
