import { createGlobalStyle } from 'styled-components';

export const MapPopupGlobalStyle = createGlobalStyle`
  .map-popup-container {
    position: absolute;
    pointer-events: auto;
    z-index: 1;
    will-change: transform;
    transition: opacity 0.2s ease;
  }

  .map-popup-bubble {
    position: relative;
    background-color: white;
    padding: 10px 32px 10px 12px;
    border-radius: 12px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
    font-family: Arial, sans-serif;
    font-size: 14px;
    overflow: visible;
    pointer-events: auto;
    min-width: 220px;
    max-width: 320px;
    width: auto;
    margin-bottom: 0;
  }

  /* a11y: show teal border only when container has focus or focus-within class */
  .map-popup-container:focus .map-popup-bubble,
  .map-popup-container.focus-within .map-popup-bubble {
    border: 2px solid #007a70; /* Teal border for a11y */
  }

  /* Style for the close button when focused */
  .map-popup-container button:focus {
    outline: 2px solid #007a70;
    outline-offset: 2px;
  }

  /* When close button is focused, remove teal border from popup */
  .popup-close-button:focus + div .map-popup-bubble,
  .popup-close-button:focus ~ .map-popup-bubble {
    border-color: #ccc !important;
  }

  .map-popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  /* Bottom triangle for popup info modal */
  .map-popup-bubble-anchor::after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 20px solid white;
    box-shadow: none;
    display: block !important;
    visibility: visible !important;
    z-index: 2;
  }

  .map-popup-content {
    display: flex;
    flex-direction: column;
  }

  .map-popup-title {
    font-weight: bold;
    margin-bottom: 2px;
    word-wrap: break-word;
    font-size: 15px;
  }

  .map-popup-address {
    margin-bottom: 6px;
    word-wrap: break-word;
    color: #555;
  }

  .map-popup-price {
    font-weight: bold;
    margin-top: 6px;
    font-size: 15px;
  }
`;
